import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { StandardHeader } from '../components/Acf/StandardHeader';
import { PageIntro } from '../components/Acf/PageIntro';
import { ContactCtaBlock } from '../components/Acf/ContactCtaBlock';
import PostList from '../components/Posts/PostList';
import { decodeEntities } from '../utils/helpers';

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { wordpressPost: page = [], allWordpressPost, categoryFilter, categories, wordpressWpSettings, siteSettings } = data;
    const { title, yoast, acf } = page ? page : { title: null, yoast: {}, acf: {} }
    const { showAuthor } = siteSettings.options
    const { title: siteTitle } = wordpressWpSettings;
    const { edges: posts } = allWordpressPost
    const featuredImage = categories.edges[0] && categories.edges[0].node.featured_image_url ? categories.edges[0].node.featured_image_url : ''
    const {
      // Standard Header fields
      standardHeaderTitleBold,
      standardHeaderTitleRegular,
      standardHeaderSubtitleBold,
      standardHeaderSubtitleRegular,
      standardHeaderImage,
      // Page Intro fields
      pageIntroTitleBold,
      pageIntroTitleRegular,
      pageIntroContent,
      pageIntroImage,
      // Contact CTA Block fields
      contactCtaTitleBold,
      contactCtaTitleRegular,
      contactCtaContent,
      contactCtaLink,
      contactCtaImage,
    } = acf;
    return (
      <Layout location={location}>
        <SEO
          title={`${
            yoast.metaTitle ?
            yoast.metaTitle :
            `Blog | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
          yoast={yoast}
        />
        <StandardHeader
          pageTitle={title}
          standardHeaderTitleBold={standardHeaderTitleBold}
          standardHeaderTitleRegular={standardHeaderTitleRegular}
          standardHeaderSubtitleBold={standardHeaderSubtitleBold}
          standardHeaderSubtitleRegular={standardHeaderSubtitleRegular}
          standardHeaderImage={standardHeaderImage}
          location={location}
        />
        <PageIntro
          pageIntroTitleBold={pageIntroTitleBold}
          pageIntroTitleRegular={pageIntroTitleRegular}
          pageIntroContent={pageIntroContent}
          pageIntroImage={pageIntroImage}
        />
        <PostList
          posts={posts}
          title="Latest posts"
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix={wordpressWpSettings.blogSlug}
        />
        <ContactCtaBlock
          contactCtaTitleBold={contactCtaTitleBold}
          contactCtaTitleRegular={contactCtaTitleRegular}
          contactCtaContent={contactCtaContent}
          contactCtaLink={contactCtaLink}
          contactCtaImage={contactCtaImage}
        />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
      }
    }
    wordpressPost: wordpressPage(slug: {eq: "news"}) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      acf {
        ... StandardHeaderNonFlexibleQuery
        ... PageIntroNonFlexibleQuery
        ... ContactCtaBlockNonFlexibleQuery
      }
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: { slug: { ne: "gatsby-demo" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          featured_image_url {
            source_url
          }
          count
        }
      }
    }
  }
`
